<template>
    <div class="home-page" id="fullpage">
         <div class="section fp-auto-height active">
            <div class="banner">
                <div class="content">
                    <p class="title">一「键」一 生</p>
                    <p class="detail">一键即生成，一见即一生之所伴；爱希游致力于通过虚拟人一站式工具，
                        让每个人都可以免费拥有高品质虚拟人畅游虚拟时空，专注于创作年轻群体喜爱的内容及玩法</p>
                </div>
            </div>
         </div>
         <div class="section fp-auto-height">
            <div class="genHuman">
            <div class="content">
                <p class="title">一站式工具 GenHuman</p>
                <p class="detail">让虚拟人拥有无限可能</p>
                <img class="view-more" @click="viewMore" src="@/assets/img/home/more.png"/>
            </div>
         </div>
         </div>
         <div class="section fp-auto-height">
            <div class="scene-box">
                <p>功能场景</p>
                <div class="leftRight">
                    <p @click="arrowclick('left')" :class="currentIndex == 0 ? 'img-gray' : ''">
                        <img src="@/assets/img/home/left_arrow.png" />
                    </p>
                    <p @click="arrowclick('right')" :class="currentIndex == 4 ? 'img-gray' : ''">
                        <img src="@/assets/img/home/right_arrow.png"/>
                    </p>
                </div>
                <div class="scence-content">
                    <div class="swipe-container">
                        <div class="swipe-item" v-for="(c, index) in categoryList" :key="index">
                            <h3>{{ c.label }}</h3>
                            <img @click="jumpTo(index)" class="image-b" :src="c.image"/>
                        </div>
                    </div>
                </div>
                <div class="scence-category">
                    <span @click="swipeFun(index)" v-for="(c, index) in categoryList" :key="c.key">{{ c.label }}</span>
                </div>
            </div>
         </div>
         <div class="section fp-auto-height">
            <ContactInformation class="footer-class"></ContactInformation>
         </div>
    </div> 
</template>
<script>
import ContactInformation from '@/components/footer.vue';
import fullpage from '../../../static/fullpage.js';
import '../../../static/easings.js';
export default {
    name: 'Home',
    components: {
        ContactInformation,
    },
    data() {
        return {
            clientHeight: 0,
            currentIndex: 0,
            myFullpage: null,
            categoryList: [
								// {
								// 		label: '元宇宙解决方案',
								// 		key: 'meta',
								// 		image: require("@/assets/img/home/scene/meta.png"),
								// },
                {
                    label: '中之人驱动虚拟人',
                    key: 'human',
                    image: require("@/assets/img/home/scene/human.png"),
                },
                {
                    label: 'AI智能虚拟人',
                    key: 'intellect',
                    image: require("@/assets/img/home/scene/intellect.png"),
                },
                {
                    label: 'AI播报虚拟人',
                    key: 'broadcast',
                    image: require("@/assets/img/home/scene/broadcast.png"),
                },
                {
                    label: '数字分身虚拟人',
                    key: 'digital',
                    image: require("@/assets/img/home/scene/digital.png"),
                },
                {
                    label: '虚拟人内容制作',
                    key: 'virtual',
                    image: require("@/assets/img/home/scene/virtual.png"),
                },
                {
                    label: '元宇宙解决方案',
                    key: 'meta',
                    image: require("@/assets/img/home/scene/meta.png"),
                },
								// {
								//     label: '中之人驱动虚拟人',
								//     key: 'human',
								//     image: require("@/assets/img/home/scene/human.png"),
								// },
            ]
        }
    },
    computed: {
    },
    beforeDestroy() {
        this.myFullpage.destroy('all');
    },  
    mounted() {
        this.myFullpage = new fullpage('#fullpage', {
            slidesNavigation: true,
            lazyLoad: true,
            autoScrolling: true,
            scrollOverflow: false,
            css3: true,
            paddingTop: '1.1rem',
            afterLoad: (anchorLink, index) => {
               
                
            },
            beforeLeave: (origin, destination, direction, trigger) => {
            },

        });     
					 this.swipeFun(0);
    },
    methods: {
			jumpTo(index) {
				if (this.currentIndex == index) this.$router.push({
					path: "/asyou/solution",
					query: {
						type: this.categoryList[index].key
					}
				})
				else this.swipeFun(index);
			},
        viewMore() {
            this.$router.push({
                path: '/asyou/genHuman',
            })
        },
        arrowclick(direction) {
					 if (direction == 'left') {
						 this.currentIndex = (this.currentIndex-1+6) % 6;
					 } else {
						 this.currentIndex = (this.currentIndex+1) % 6;
					 }
            this.swipeFun(this.currentIndex);
        },
        swipeFun(index) {
            this.currentIndex = index;
						// console.log(this.currentIndex);
            let swipeDomList = document.querySelectorAll('.swipe-item');
						/// 间隙为6, current宽度为58%, 高度100%, 其他宽度, 36%, 高度80%
                for(let i = 0; i < swipeDomList.length; i++) { 
                    let swipeItemStyle = swipeDomList[i].style;
										if (i == this.currentIndex) { // 当前值
											swipeItemStyle.width = '58%';
											swipeItemStyle.height = "100%";
											swipeItemStyle.left = 0;
											swipeItemStyle.opacity = 1;
										} else if (i == (this.currentIndex + 1) % 6){
											swipeItemStyle.width = '36%'; 
											swipeItemStyle.height = "80%";
											swipeItemStyle.left = '64%';
											swipeItemStyle.opacity = 1; 
										} else if ((6 - this.currentIndex + i) % 6 < 4){
											swipeItemStyle.opacity = 0;
											swipeItemStyle.width = '36%';
											swipeItemStyle.height = "80%";
											swipeItemStyle.left = '106%';
										} else {
											swipeItemStyle.opacity = 0;
											swipeItemStyle.width = '36%';
											swipeItemStyle.height = "80%";
											swipeItemStyle.left = '-36%';
										}
									}
								
        },
        exchangeCategory(index, number) {
            this.currentIndex = index;
            let c = this.categoryList[index];
            let nextI = this.categoryList[(index+number)%this.categoryList.length];
            this.currentScence = {
                labelB: c.label,
                labelS: nextI.label,
                image: c.image,
                imageS: nextI.imageS
            }
        },
        
    }
}

</script>
<style lang="scss">
    .fp-overflow {
        max-height: 100% !important;
    }
    .section.footerss .fp-tableCell{//修改最后一屏display属性
        display: block!important;
    }
</style>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
    .banner {
            position: relative;
            height: $extra-header-height;
            background: url(../../assets/img/home/big-bg.png) center no-repeat;
            background-size: cover;
            overflow: hidden;
            width: 100%;
            .content {
                position: absolute;
                z-index: 100;
                bottom: 0.6rem;
                left: $whole-lr;
                right: $whole-lr;
                .title {
                    @include font-1;
                }
                .detail {
                    @include de-0-2;
                    margin-top: 0.2rem;
                }
            }
    }

    .genHuman {
        height: $extra-header-height;
        background: url(../../assets/img/home/genHuman.png) center center no-repeat;
        background-size: contain;
        position: relative;
        width: 100%;
        overflow: hidden;
        
        .content {
            position: absolute;
            top: calc(var(--vh) * 6);
            text-align: center;
            z-index: 1;
            left: 0;
            right: 0;
            img.view-more {
                position: relative;
                cursor: pointer;
                margin-top: 1.1rem;
                height: 0.59rem;
            }
            
            .title {
                @include font-2;
            }
            
            .detail {
                    @include de-0-2;
                    margin-top: 0.2rem;
            }
        } 
    }

    .scene-box {
        height: $extra-header-height;
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-left: $whole-lr;
        background: radial-gradient(closest-side at 50% 90%, #091C4F, #000);
        
        p {
            margin-top: 0.38rem;
            font-size: 0.24rem;
            font-family: MicrosoftYaHei;
            color: rgba($color: #F5F5FF, $alpha: 0.6);
            line-height: 0.3rem;
            letter-spacing: 1px;
        }
        .leftRight {
            position: absolute;
            right: $whole-lr;
            top: 0.8rem;
            display: flex;
            z-index: 999;
                img {
                    position: relative;
                    cursor: pointer;
                    height: 0.16rem;
                    margin-left: 0.48rem;
                }
                img:hover {
                    opacity: 0.6;
                }
                .img-gray img {
                    opacity: 0.6;
                }
        }
        
        .scence-content {
            position: relative;
            width: 100%;
            height: calc(100% - 2rem);
            margin-top: 10px;
            overflow-x: hidden;
            .swipe-container {
                position: relative;
                height: 100%; // 43.88
                
                .swipe-item {
                    width: 36%;
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: all .4s ease-in-out;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    h3  {
                        width: 100%;
                        margin-top: 0.16rem;
                        margin-bottom: 0.32rem;
                        @include font-2;
                        font-weight: bold;
                    }
                    img {
                        height: calc(100% - 1.2rem);
                        transition: all .4s ease-in-out;
                        width: 100%;
                        object-fit: contain;
												cursor: pointer;
                    }
                }
								// :nth-child(1) {
								//     left: -58%;
								//     width: 58%;
								// }
                :nth-child(1) {
                    left: 0;
                    width: 58%;
                }
                :nth-child(2) {
                    left: 64%;
                    height: 80%;
                }
                :nth-child(3) {
                    left: 106%;
                }
                :nth-child(4) {
                    left: 148%;
                }
                :nth-child(5) {
                    left: 190%;
                }
                :nth-child(6) {
                    left: -58%;
                }
            }
        }
        .scence-category {
            display: flex;
            align-items: center;
            margin-top: 0.64rem;

            span {
                cursor: pointer;
                display: inline-block;
                margin-right: 0.64rem;
                
                @include de-0-2;
            }
        }
    }
    .footer-class {
        margin-top: 1.12rem;
    }
</style>
